<template>
  <div>
    <div class="company-wrap" v-if="!isPc">
      <title-module :titleInfo="titleInfo" />
      <div class="content">
        <div class="img">
          <!-- <img src="../../../assets/img/company-1.png" alt="" /> -->
          <img src="../../../assets/img/company.png" alt="" />
        </div>
        <div class="text">
          <p>
           安徽儒彧电子信息科技有限公司（简称“儒彧”）成立于2018年1月，是喜尔美集团旗下科技型公司，立志于为城市提供全品类垃圾分类一站式解决方案。产品喜尔美云柜以前端智能化硬件产品、喜尔美云柜APP为服务端口，依托后端物联网、云计算、大数据、环保信用体系、智能运营系统，构建城市场景的分类运营模型，为政府、企业、再生资源公司、垃圾处理企业打造产业生态，推动垃圾分类“两网融合”模式创新，最终实现生活垃圾分类收集、分类清运、分类处理的一站式服务体系，提升城市再生资源回收率。助力智慧社区、智慧城市建设，有效推进绿色可持续发展。
          </p>
        </div>
      </div>
    </div>
    <div class="company-wrap-pc .wd-1200" v-else>
      <div class="text-content">
        <div class="text-wrap">
          <div class="title">
            <span class="title-text">
              <span class="color-red">公司</span>
              <span class="color-black">简介</span>
            </span>
            <span class="text-eg">COMPANY PROFILE</span>
          </div>
          <div class="content">
            <p>
           安徽儒彧电子信息科技有限公司（简称“儒彧”）成立于2018年1月，是喜尔美集团旗下科技型公司，立志于为城市提供全品类垃圾分类一站式解决方案。产品喜尔美云柜以前端智能化硬件产品、喜尔美云柜APP为服务端口，依托后端物联网、云计算、大数据、环保信用体系、智能运营系统，构建城市场景的分类运营模型，为政府、企业、再生资源公司、垃圾处理企业打造产业生态，推动垃圾分类“两网融合”模式创新，最终实现生活垃圾分类收集、分类清运、分类处理的一站式服务体系，提升城市再生资源回收率。助力智慧社区、智慧城市建设，有效推进绿色可持续发展。
            </p>
          </div>
          <div class="button">
            <moreButton @seeMore="seeMore" />
          </div>
        </div>
        <!-- <more-button @seeMore="toBrief" /> -->
      </div>
      <div class="image">
        <img src="../../../assets/img-pc/jianjie-new.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import titleModule from "@/components/title";
import moreButton from "@/components/seeMore";
export default {
  props: ["isPc"],
  components: {
    titleModule,
    moreButton
  },
  data() {
    return {
      titleInfo: {
        titleRed: "公司",
        titleWhite: "简介",
        remark: "COMPANY PROFILE",
        noBold: true
      }
    };
  },

  methods: {
    seeMore() {
      this.$router.push("/about");
    }
  },

  created() {}
};
</script>

<style lang="less" scoped>
.company-wrap {
  padding: 48px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(191, 206, 222, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 100%;
    .img {
      margin: 40px 0 35px;
      display: flex;
      justify-content: space-between;
      img {
        width: 1030px;
        height: 640px;
      }
    }
    .text {
      text-align: left;
      p {
        font-size: 30px;
        line-height: 60px;
        letter-spacing: 0px;
        color: #1d1b1b;
        text-indent: 2em;
      }
    }
  }
}
</style>

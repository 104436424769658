<template>
  <div>
    <div class="product-wrap" v-if="!isPc">
      <div class="lihua-bg"></div>
      <div class="title">{{ info.title }}</div>
      <div class="product">
        <div
          class="product-item"
          v-for="item in listData"
          :key="item.id"
          @click="toDetails(item)"
        >
          <img :src="item.mainPicture" alt="" />
          <span>{{ item.title | filterName }}</span>
        </div>
      </div>
      <div class="see-more">
        <span @click="seeMore(info)">查看更多</span>
        <div @click="seeMore(info)" class="icon"></div>
      </div>
    </div>
    <div class="product-wrap-pc" :class="className" v-else>
      <titleModule :titleInfo="titleInfo" :isPc="isPc" />
      <div
        class="product-pc"
        :class="{ 'product-pc-vintage': className === 'vintageWine-pc' }"
      >
        <div
          class="vintage-pic"
          v-if="className === 'vintageWine-pc' && listData.length"
        >
          <img src="../../../assets/img-pc/vintage.png" alt="" />
          <div class="seemore" @click="seeMore">
            了解更多
          </div>
        </div>
        <div
          class="product-item"
          v-for="item in listData"
          :key="item.id"
          @click="toDetails(item)"
        >
          <img :src="item.mainPicture" alt="" />
          <div class="shade" v-if="info.title === '电子花炮'">
            <div class="shade-title">{{ item.title }}</div>
            <div class="shade-button">详情</div>
          </div>
        </div>
      </div>
      <moreButton
        v-if="className !== 'vintageWine-pc'"
        :class="{ 'color-f': info.title === '电子花炮' }"
        @seeMore="seeMore"
      />
    </div>
  </div>
</template>

<script>
import { getFeatureContent } from "@/api/index.js";
import titleModule from "@/components/title";
import moreButton from "@/components/seeMore";
export default {
  props: ["info", "isPc"],
  components: {
    titleModule,
    moreButton
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 6,
      sort: "time",
      order: "asc",
      listData: [],
      titleInfo: {
        titleRed: "",
        titleWhite: "电子花炮",
        remark: "COMPANY PROFILE",
        noBold: this.isPc
      },
      className: ""
    };
  },
  filters: {
    filterName(val) {
      let vals = "";
      vals = val.substring(0, 4);
      return vals;
    }
  },
  methods: {
    async onLoad() {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sort: this.sort,
        order: this.order,
        navigationId: this.info.id
      };
      try {
        const { result } = await getFeatureContent(params);
        const data = result ? result.content : [];
        this.listData.push(...data);
        // this.newsId && this.hasIdToDetail(this.newsId, this.listData);
      } catch (error) {
        this.$message.error("获取内容失败！");
      }
    },
    toDetails(item) {
      this.$router.push({
        path: "/products",
        query: { id: item.id, navigationId: item.navigationId }
      });
    },
    seeMore(info) {
      this.$router.push({
        path: "/products"
        // query: { activeName: info.path }
      });
    },
    nowClassName() {
      let className = "";
      this.listData = [];
      switch (this.info.path) {
        case "/fireworks":
          className = "fireworks-pc";
          this.pageSize = this.isPc ? 8 : 6;
          this.titleInfo = {
            titleRed: "",
            titleWhite: "电子花炮",
            remark: "ELECTRONIC FLOWER EXPLOSION"
          };
          break;
        case "/vintageWine":
          className = "vintageWine-pc";
          this.pageSize = this.isPc ? 4 : 6;
          this.titleInfo = {
            titleRed: "名酒",
            titleWhite: "茗茶",
            remark: "FAMOUS WINE AND TEA "
          };
          break;
        case "/wedding":
          className = "wedding-pc";
          this.pageSize = 6;
          this.titleInfo = {
            titleRed: "婚庆",
            titleWhite: "用品",
            remark: "WEDDING SUPPLIES"
          };
          break;
      }
      this.className = className;
      this.onLoad();
    }
  },
  created() {
    this.nowClassName();
  },
  watch: {
    isPc() {
      this.nowClassName();
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.product-wrap {
  height: 912px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(191, 206, 222, 0.2);
  border-radius: 20px;
  padding: 48px;
  .title {
    width: 623px;
    height: 91px;
    background-image: url("../../../assets/img/product_bgi.png");
    background-size: 623px;
    margin: 0 auto 42px;
    font-size: 36px;
    font-weight: bold;
    line-height: 70px;
    letter-spacing: 7px;
    color: #ffffff;
  }
  .lihua-bg {
    width: 100%;
    height: 243px;
    background-color: rgba(0, 0, 0, 0);
    // background-image: url("../../../assets/img/product-bg.png");
    background-size: contain;
    position: absolute;
    top: -20px;
    left: 0;
  }
  .product {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-around;
    .product-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        border-radius: 10px;
        border: solid 6px #eab472;
        width: 192px; //292
        height: 180px; //220
        padding: 20px 50px;
      }
      span {
        font-size: 30px;
        line-height: 30px;
        font-weight: bold;
        font-stretch: normal;
        color: #333333;
        margin: 20px 0 40px;
      }
    }
  }

  .see-more {
    height: 36px;
    display: flex;
    margin: 10px auto 0;
    justify-content: center;
    align-items: center;
    span {
      font-size: 36px;
      font-weight: bold;
      line-height: 30px;
      color: #b90a17;
    }
    .icon {
      width: 36px;
      height: 36px;
      background: url("../../../assets/img/see-more.png");
      background-size: 36px;
    }
  }
}
</style>

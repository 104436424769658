<template>
  <div>
    <div :class="isPc ? 'join-wrap-pc' : 'join-wrap'">
      <div class="wraps">
        <title-module
          :titleInfo="titleInfo"
          class="title-module"
          :isPc="isPc"
        />
        <div class="content">
          <div class="form">
            <div class="input-title">电话</div>
            <div class="input">
              <input type="text" v-model="tel" placeholder="请输入您的电话" />
            </div>
            <div class="input-title">验证码</div>
            <div class="input code-wrap">
              <input
                type="text"
                v-model="code"
                placeholder="请输入您的验证码"
              />
              <div
                class="code-btn"
                :class="codeDown ? 'disabled' : ''"
                @click="getCode"
              >
                {{ !codeDown ? "获取验证码" : "剩余" + countDown + " s" }}
              </div>
            </div>
            <div class="input-title">备注</div>
            <div class="input">
              <textarea
                v-model="remark"
                rows="4"
                placeholder="简单描述您的需求，我们会尽快联系你"
              ></textarea>
            </div>
            <div class="btn">
              <!-- <div class="reset" @click="reSet">重置</div> -->
              <div class="submit" @click="save">提交</div>
            </div>
          </div>
          <div class="list" v-if="isPc">
            <img src="../../../assets/img-pc/servic.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleModule from "@/components/title";
import { getCode, commitCode } from "@/api/index.js";
export default {
  props: ["isPc"],
  components: {
    titleModule
  },
  mounted() {
    this.timer = setInterval(this.scroll, 2000);
  },
  data() {
    return {
      animate: true,
      timer: null,
      timer2: null,
      titleInfo: {
        titleRed: "咨询",
        titleWhite: "服务",
        remark: "INVITE INVESTMENT AND JOIN IN"
      },
      pepoleData: [
        {
          time: "1分钟前",
          title: "热烈欢迎 季** 加盟喜尔美公司"
        },
        {
          time: "8分钟前",
          title: "热烈欢迎 唐** 加盟喜尔美公司"
        },

        {
          time: "13分钟前",
          title: "热烈欢迎 白* 加盟喜尔美公司"
        },

        {
          time: "36分钟前",
          title: "热烈欢迎 赵** 加盟喜尔美公司"
        },

        {
          time: "52分钟前",
          title: "热烈欢迎 孙** 加盟喜尔美公司"
        },

        {
          time: "1小时10分钟前",
          title: "热烈欢迎 王** 加盟喜尔美公司"
        },

        {
          time: "1小时20分钟前",
          title: "热烈欢迎 马** 加盟喜尔美公司"
        },

        {
          time: "1小时40分钟前",
          title: "热烈欢迎 李** 加盟喜尔美公司"
        },

        {
          time: "1小时42分钟前",
          title: "热烈欢迎 张** 加盟喜尔美公司"
        },

        {
          time: "1小时5分钟前",
          title: "热烈欢迎 刘* 加盟喜尔美公司"
        }
      ],
      name: "",
      code: "",
      tel: "",
      remark: "",
      codeDown: false,
      countDown: 60,
      int: null
    };
  },

  methods: {
    scroll() {
      this.animate = true; // 过渡动画状态
      if (this.timer2) clearTimeout(this.timer2);
      this.timer2 = setTimeout(() => {
        this.pepoleData.push(this.pepoleData[0]); // 将数组的第一个元素添加到数组
        this.pepoleData.shift(); //删除数组的第一个元素
        this.animate = false; // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      }, 1500);
    },

    reSet() {
      this.tel = "";
      this.code = "";
      this.remark = "";
      if (this.countDown !== 0 && this.countDown !== 60) {
        clearInterval(this.int);
        this.countDown = 60;
        this.codeDown = false;
      }
    },
    save() {
      if (!/^1[3456789]\d{9}$/.test(this.tel)) {
        return this.$message.error("请先填写正确的联系方式");
      } else if (!this.code) {
        return this.$message.error("请填写验证码！");
      }
      commitCode({
        // name: this.name,
        mobile: this.tel,
        code: this.code
      }).then(res => {
        if (res.success) {
          this.$message.success("信息提交成功！");
          this.reSet();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getCode() {
      if (!/^1[3456789]\d{9}$/.test(this.tel)) {
        return this.$message.error("请先填写正确的联系方式");
      } else if (this.codeDown) {
        return false;
      }
      getCode({ mobile: this.tel }).then(res => {
        if (res.success) {
          this.codeDown = true;
          this.int = setInterval(this.countDownHandle, 1000);
          this.$message.success("获取验证码成功，请留意短信通知");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    countDownHandle() {
      if (this.countDown === 0) {
        this.codeDown = false;
        clearInterval(this.int);
        this.countDown = 60;
      } else {
        this.countDown--;
      }
    }
  },

  created() {},
  beforeDestroy() {
    clearInterval(clearInterval(this.timer));
  }
};
</script>

<style lang="less" scoped>
.join-wrap {
  margin-top: 21px;
  padding: 48px 86px;
  background-color: #fff;
  .title-module {
    margin-bottom: 20px;
  }
  .form {
    text-align: left;
    .input-title {
      font-size: 30px;
      color: #333333;
      padding: 39px 0 19px;
    }
    .input {
      input {
        box-sizing: border-box;
        width: 100%;
        height: 101px;
        font-size: 30px;
        border-radius: 10px;
        border: solid 1px #e5e5e5;
        padding-left: 30px;
      }

      &.code-wrap {
        position: relative;
        input {
          width: 100%;
        }
        .code-btn {
          position: absolute;
          top: 0;
          right: 0;
          width: 250px;
          line-height: 101px;
          // background-color: #5ea9f5;
          text-align: center;
          color: #0089fe;
          cursor: pointer;
          font-size: 30px;
          &::before {
            content: "";
            display: block;
            width: 2px;
            border-left: 2px solid #e5e5e5;
            height: 40px;
            clear: both;
            position: absolute;
            top: 32px;
          }
        }
      }
      textarea {
        box-sizing: border-box;
        width: 100%;
        border: solid 1px #e5e5e5;
        padding: 30px;
        border-radius: 10px;
        font-size: 30px;
        resize: none;
        margin-bottom: 39px;
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      .reset,
      .submit {
        width: 100%;
        height: 89px;
        border-radius: 10px;
        font-size: 30px;
        line-height: 89px;
        text-align: center;
      }
      .reset {
        background-color: #e8eaed;
        color: #666666;
      }
      .submit {
        background-image: linear-gradient(
            93deg,
            #004dff 0%,
            #008ffe 37%,
            #00d0fd 100%
          ),
          linear-gradient(#d4101e, #d4101e);
        background-blend-mode: normal, normal;
        box-shadow: 0px 6px 10px 0px rgba(66, 135, 252, 0.3);
        border-radius: 10px;
        color: #fff;
      }
    }
  }
}
</style>

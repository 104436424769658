<template>
  <div class="see-more-wrap" @click="seeMore">
    <span class="text">了解更多</span>
    <span class="icon"></span>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    seeMore() {
      this.$emit("seeMore");
    }
  },

  created() {}
};
</script>

<style lang="less" scoped>
.see-more-wrap {
  cursor: pointer;
}
</style>

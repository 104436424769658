<template>
  <div>
  <!--新增内容start-->
    <div class="industry-wrap-pc" >
      <title-module
        :titleInfo="titleit"
        :isPc="isPc"
        class="industry-wrap-pc-title"
      /> 
      <div class="product-show">
        <img style="width:27%" src="../../../../src/assets/img/zhan-1.png" alt="" />
        <div class="product-show-left">
            <img style="width:100%" src="../../../../src/assets/img/zhan-2.png" alt="" />
            <img style="width:100%;margin-top:20px" src="../../../../src/assets/img/zhan-3.png" alt="" />
        </div>
      </div>
      <!-- <div class="see-more">
        <moreButton @seeMore="seeMore" class="button" />
      </div> -->
    </div>
   
    <div class="industry-wrap-pc" >
      <title-module
        :titleInfo="titleyin"
        :isPc="isPc"
        class="industry-wrap-pc-title"
      /> 
      <div>
        <img style="width:50%" src="../../../../src/assets/img/product_img.png" alt="" />
     
      </div>
      <!-- <div class="see-more">
            <moreButtons @software="software" class="button" />
      </div> -->
    </div>
  <!--新增内容end-->
    
    <div class="industry-wrap" v-if="!isPc">
      <title-module :titleInfo="titleInfo" />
      <div class="content-wrap">
        <div
          class="info-item"
          v-for="item in listData"
          :key="item.id"
          @click="toDetails(item)"
        >
          <div class="pic">
            <img :src="item.mainPicture" alt="" />
          </div>
          <div class="text">
            <div class="title">{{ item.title }}</div>
            <div class="content">
              {{ item.remark }}
            </div>
            <div class="time">{{ item.time }}</div>
          </div>
        </div>
        <div class="see-more" @click="seeMore">
          <!-- <span>查看更多</span> -->
          <div class="icon"></div>
        </div>
      </div>
    </div>
    <!-- <div class="industry-wrap-pc" v-else>
      <title-module
        :titleInfo="titleInfo"
        :isPc="isPc"
        class="industry-wrap-pc-title"
      />
      <div class="content-wrap">
        <div
          class="info-item"
          v-for="item in listData"
          :key="item.id"
          @click="toDetails(item)"
        >
          <div class="pic">
            <img :src="item.mainPicture" alt="" />
          </div>
          <div class="text">
            <div class="title">
              <span class="title-text">{{ item.title }}</span>
              <span class="time"> {{ item.time }}</span>
            </div>
            <div class="content">
              {{ item.remark }}
            </div>
          </div>
        </div>
      </div>
      <div class="see-more">
        <moreButton @seeMore="seeMore" class="button" />
      </div>
    </div> -->
  </div>
</template>

<script>
import titleModule from "../../../components/title";
import { getFeatureContent } from "@/api/index.js";
import moreButton from "@/components/seeMore";
import moreButtons from "@/components/software"
export default {
  props: ["isPc"],
  components: {
    titleModule,
    moreButton,
    moreButtons
  },
  data() {
    return {
      titleit:{
        titleRed: "喜尔美",
        titleWhite: "云柜",
      },

      titleyin:{
        titleRed: "云柜",
        titleWhite: "app",
      },
      titleInfo: {
        titleRed: "行业",
        titleWhite: "资讯",
        remark: "INDUSTRY INFORMATION"
      },
      pageNumber: 1,
      pageSize: 3,
      sort: "time",
      order: "asc",
      listData: []
    };
  },

  methods: {
    async onLoad() {
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.isPc ? 4 : this.pageSize,
        sort: this.sort,
        order: this.order,
        navigationId: "1305778760947208192"
      };
      try {
        const { result } = await getFeatureContent(params);
        const data = result ? result.content : [];
        this.listData.push(...data);
      } catch (error) {
        console.log(error);
        this.$message.error("获取内容失败！");
      }
    },
    toDetails(item) {
      this.$router.push({
        path: "/information",
        query: { id: item.id, navigationId: item.navigationId }
      });
    },
    seeMore() {
      this.$router.push("/information");
    },
    software() {
      this.$router.push("../software.vue");
    }
  },

  created() {
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.product-show-left{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 15px;
}
.product-show{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 25px;
}
.industry-wrap {
  padding: 48px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px 0px rgba(191, 206, 222, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-wrap {
    width: 1025px;
    margin-top: 50px;
    .info-item {
      display: flex;
      position: relative;
      margin-bottom: 80px;
      .pic {
        width: 235px;
        height: 235px;
        // overflow: hidden;
        margin-right: 28px;
        img {
          width: 235px;
          height: 235px;
        }
      }
      .text {
        flex: 1;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          width: 100%;
          font-size: 36px;
          font-weight: bold;
          color: #1d1b1b;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .content {
          font-size: 24px;
          line-height: 36px;
          letter-spacing: 0px;
          color: #6b6464;
          max-height: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .time {
          font-size: 24px;
          color: #adadad;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -40px;
          width: 768px;
          height: 2px;
          background-color: #f7f7f7;
        }
      }
    }
    .see-more {
      height: 36px;
      display: flex;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      span {
        font-size: 36px;
        font-weight: bold;
        line-height: 30px;
        color: #4287fc;
      }
      .icon {
        width: 221px;
        height: 60px;
        background: url("../../../assets/img/see-more.png");
        background-size: 221px;
      }
    }
  }
}
</style>
